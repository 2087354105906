import { useSessionStorageValue } from '@react-hookz/web';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import { useTranslations } from 'next-intl';
import { useParams, usePathname } from 'next/navigation';

import type { Community, CommunityMember } from '@zealy/queries';
import {
  useAuthenticatedUser,
  useCurrentSprint,
  useFailedAutomations,
  useInbox,
  useListPartnerships,
  usePendingReviewsCount,
} from '@zealy/queries';
import { roleIsAtLeast } from '@zealy/utils';

import { useCommunitiesFlags } from '#context/FeatureFlags';

import type { BaseSidebarItem } from './Sidebar.constants';
import { BASE_SIDEBAR_ITEMS } from './Sidebar.constants';

const useUnreadNotifications = ({ enabled }: { enabled: boolean }) => {
  const { data: user } = useAuthenticatedUser<CommunityMember>();

  const discordAlerts = useFailedAutomations(undefined, {
    enabled: roleIsAtLeast(user?.role, 'admin'),
  });

  const inbox = useInbox(undefined, undefined, undefined, enabled);

  let unreadNotifications = 0;

  if (discordAlerts.data?.missingPermissionCount) unreadNotifications += 1;

  if (inbox.data?.pages?.[0]?.totalUnread)
    unreadNotifications += inbox.data?.pages?.[0]?.totalUnread;

  return unreadNotifications;
};

const usePendingReviews = ({ enabled, subdomain }: { enabled: boolean; subdomain: string }) => {
  const oldReviews = usePendingReviewsCount({
    enabled,
  }).data;

  return oldReviews;
};

const communityHasLaunched = (community: Community) =>
  !!community?.launchDate && new Date(community?.launchDate) < new Date();

const useShowSidebarItem = (community: Community, user?: CommunityMember) => {
  const { analytics, crm } = useCommunitiesFlags(['crm', 'analytics']);
  const isTooRecent = dayjs(community.createdAt).isAfter(dayjs().subtract(3, 'day'));

  return (item: BaseSidebarItem) => {
    if (item.role && !roleIsAtLeast(user?.role, item.role)) return false;

    if (item.value === 'analytics' && isTooRecent && !analytics.enabled) return false;

    if (item.value === 'members' && isTooRecent && !crm.enabled) return false;

    return true;
  };
};

const useSidebarBadge = (community: Community, user?: CommunityMember) => {
  const hasLaunched = communityHasLaunched(community);
  const incomingPartnershipsQuery = useListPartnerships({
    subdomain: community.subdomain,
    query: { status: 'pending', position: 'incoming' },
    enabled: roleIsAtLeast(user?.role, 'editor') && hasLaunched,
  });

  const badges = {
    notifications: useUnreadNotifications({
      enabled: roleIsAtLeast(user?.role, 'guest') && hasLaunched,
    }),

    reviews: usePendingReviews({
      enabled: roleIsAtLeast(user?.role, 'reviewer'),
      subdomain: community.subdomain,
    }),

    partnerships: incomingPartnershipsQuery.data?.length,
  } as const;

  return (item: BaseSidebarItem) => {
    if (item.value in badges) {
      const badge = badges[item.value as keyof typeof badges];

      return !!badge && badge > 99 ? '99+' : badge;
    }
  };
};

const getURL = (
  community: Community,
  user: CommunityMember | undefined,
  value: string,
  childValue = '',
) => {
  if (value === 'questboard' && community?.v2) {
    return `/cw/${community.subdomain}/questboard/${
      roleIsAtLeast(user?.role, 'editor') ? 'admin' : childValue === 'general' ? '' : childValue
    }`;
  }
  if (value === 'questboard') {
    return `/c/${community.subdomain}/questboard?view=${childValue}`;
  }

  if (value === 'analytics') {
    return `/cw/${community.subdomain}/analytics`;
  }

  if (value === 'partnerships') {
    return `/cw/${community.subdomain}/partnerships`;
  }

  return `/cw/${community.subdomain}/${value ?? ''}`;
};

type SprintItem = {
  value: string;
  href: string;
  selected: boolean;
  label: string;
  className: string;
  onClick?: () => void;
};

const useSidebarChildren = (community: Community, user?: CommunityMember) => {
  const pathname = usePathname();
  const { ff_community_awards } = useCommunitiesFlags(['ff_community_awards']);
  const { set: setSprintView } = useSessionStorageValue<boolean>(
    `sprintView:${community.subdomain}`,
  );
  const { sprintId } = useParams<{ sprintId?: string }>();

  const t = useTranslations('sidebar');
  const hasLaunched = communityHasLaunched(community);
  const currentSprint = useCurrentSprint(hasLaunched && user?.role !== 'banned');
  const hasSprint = !!currentSprint.data;

  return (item: BaseSidebarItem, selected: boolean) => {
    const baseUrl = getURL(community, user, item.value);
    const children: Array<SprintItem> = [];

    if (!ff_community_awards.enabled && !hasSprint) {
      return [];
    }

    if (item.value === 'questboard' && !roleIsAtLeast(user?.role, 'editor')) {
      if (hasSprint || ff_community_awards.enabled) {
        children.push({
          value: 'general',
          href: getURL(community, user, item.value, 'general'),
          selected: selected && !pathname.includes(`/cw/${community.subdomain}/questboard/`),
          label: t(`items.general`),
          className: 'w-full',
          onClick: () => setSprintView(false),
        });
      }

      if (hasSprint) {
        children.push({
          value: 'sprints',
          href: getURL(community, user, item.value, 'sprints'),
          selected: selected && pathname.includes(getURL(community, user, item.value, 'sprints')),
          label: t(`items.sprints`),
          className: 'w-full',
          onClick: () => setSprintView(true),
        });
      }

      if (ff_community_awards.enabled) {
        children.push({
          value: 'awards',
          href: getURL(community, user, item.value, 'awards'),
          selected: selected && pathname.includes(getURL(community, user, item.value, 'awards')),
          label: t(`items.awards`),
          className: 'w-full',
          onClick: () => setSprintView(false),
        });
      }
    }

    if (item.value === 'leaderboard') {
      children.push({
        value: 'all-time',
        href: baseUrl,
        selected: selected && !sprintId,
        label: t(`items.all-time`),
        className: 'w-full',
      });

      children.push({
        value: 'sprints',
        href: `${baseUrl}/${currentSprint.data?.id}`,
        selected: selected && !!sprintId,
        label: t(`items.sprints`),
        className: 'w-full',
      });
    }

    return children;
  };
};

const itemIsDisabled = (
  community: Community,
  user: CommunityMember | undefined,
  item: BaseSidebarItem,
) =>
  !communityHasLaunched(community) &&
  !roleIsAtLeast(user?.role, 'reviewer') &&
  item.value !== 'questboard';

export function useSidebar({ user, community }: { user?: CommunityMember; community: Community }) {
  const t = useTranslations('sidebar');
  const sidebarFilter = useShowSidebarItem(community, user);
  const badges = useSidebarBadge(community, user);
  const sprintItems = useSidebarChildren(community, user);

  const pathname = usePathname();
  const currentTab = useMemo(() => {
    const [, match] = /\/c[w]?\/[\w-]+\/([\w-]+)/.exec(pathname) ?? [];
    return match;
  }, [pathname]);

  return BASE_SIDEBAR_ITEMS.filter(sidebarFilter).map(item => {
    const children = sprintItems(item, currentTab === item.value);
    const expandable = children.length > 0;

    return {
      ...item,
      children,
      expandable,
      badge: badges(item),
      href: getURL(community, user, item.value),
      selected: currentTab === item.value,
      label: t(`items.${item.value}`),
      className: 'w-full',
      disabled: itemIsDisabled(community, user, item) || user?.role === 'banned',
    };
  });
}
